import React, { useState } from 'react';
import PropTypes from 'prop-types';
import AccordionItem from '../../common/AccordionItem';
import BannerProgressItemHeader from '../../common/BannerProgressItemHeader';
import StepCheckMark from '../../../assets/icons/step_check_mark.svg';
import StatusCancelled from '../../../assets/icons/status_cancelled.svg';
import DispatchItem from '../../common/DispatchItem';
import { COMPLETION_DATE_STATUS, MILESTONE_STATUS } from '../../../constants/salesOrder';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';

const renderStatusIcon = (status) => {
  const notStarted = status === COMPLETION_DATE_STATUS.TBD;

  if (notStarted) {
    return <span className={'status-icon circle not-started'} />;
  } else if (status === COMPLETION_DATE_STATUS.CANCELLED) {
    return <img className={'status-icon'} src={StatusCancelled} alt="Status icon" />;
  } else if (status === COMPLETION_DATE_STATUS.IN_PROGRESS) {
    return <span className={'status-icon circle in-progress'} />;
  }

  return <img className={'status-icon'} src={StepCheckMark} alt="Status icon" />;
};

const JobStage = (props) => {
  const onClickIfLink = (link, soNumber) => {
    if (link) props.goToSummary(soNumber);
  };

  return (
    <OverlayScrollbarsComponent defer className="job-stage-container-scrollbar">
      <div className="job-stage-container">
        <div className="job-stage-titles">
          {[{ title: 'Status', size: 5 }].concat(props.content.itemHeaders).map((item, idx) => (
            <div key={idx} className={`job-stage-title size-${item.size}`}>
              {idx === 0 ? (
                <div className="status-icon-container">{''}</div>
              ) : (
                <div className="job-stage-text">{item.title}</div>
              )}
            </div>
          ))}
        </div>
        <hr />
        <div className="job-stage-values">
          {props.content.itemValues.length ? (
            props.content.itemValues.map((valueRow, itemIdx) => (
              <React.Fragment key={itemIdx}>
                <div key={itemIdx} className="job-stage-row">
                  {[''].concat(valueRow).map((value, idx) => {
                    let jobStageValueClasses = `job-stage-value size-${
                      props.content.itemHeaders[idx - 1]?.size || 5
                    }`;
                    idx === 0 && (jobStageValueClasses += ' center');
                    props.content.itemHeaders[idx - 1]?.boldValue &&
                      (jobStageValueClasses += ' bold');
                    props.content.itemHeaders[idx - 1]?.link && (jobStageValueClasses += ' link');

                    return (
                      <div key={idx} className={jobStageValueClasses}>
                        {idx === 0 ? (
                          <div className="status-icon-container">
                            {renderStatusIcon(props.content.rowProgress(valueRow))}
                          </div>
                        ) : (
                          <div
                            className="job-stage-text"
                            onClick={() =>
                              onClickIfLink(props.content.itemHeaders[idx - 1]?.link, value)
                            }>
                            {value}
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
                {itemIdx !== props.content.itemValues.length - 1 &&
                props.content.itemValues.length > 1 ? (
                  <hr />
                ) : null}
              </React.Fragment>
            ))
          ) : (
            <p className="no-data">None at this time</p>
          )}
        </div>
      </div>
    </OverlayScrollbarsComponent>
  );
};

const JobSteps = (props) => {
  const [expandedSteps, setExpandedSteps] = useState(props.steps.map(() => false));

  const setExpandedStep = (idx, expanded) => {
    const newExpandedSteps = [...expandedSteps];
    newExpandedSteps[idx] = expanded;
    setExpandedSteps(newExpandedSteps);
  };

  return (
    <>
      {props.steps.map((step, idx) => {
        const completed = props.chartItems.find((item) => item.id === step.id)?.completed || 0;
        const total = props.chartItems.find((item) => item.id === step.id)?.total || 0;
        const percentage = Math.ceil((completed / total) * 100);

        const displayStage = step.isMilestone
          ? step.items.find((i) => i.completionDate !== MILESTONE_STATUS.NotStarted)
          : total > 0;

        if (!displayStage) return null;

        return step.isMilestone ? (
          <DispatchItem key={idx} item={step} />
        ) : (
          <AccordionItem
            key={idx}
            headerElement={
              <BannerProgressItemHeader
                step={step}
                percentageCompleted={percentage || 0}
                onClickExpand={() => setExpandedStep(idx, !expandedSteps[idx])}
                expanded={expandedSteps[idx]}
              />
            }
            contentElement={<JobStage content={step} goToSummary={props.goToSummary} />}
            contentExpanded={expandedSteps[idx]}
          />
        );
      })}
    </>
  );
};

JobSteps.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({
        title: PropTypes.string,
        subtitle: PropTypes.string,
        itemHeaders: PropTypes.arrayOf(
          PropTypes.shape({
            title: PropTypes.string,
            size: PropTypes.number,
            countForPercentage: PropTypes.number,
            boldValue: PropTypes.bool,
            link: PropTypes.bool,
          }),
        ),
        itemValues: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
        rowProgress: PropTypes.func,
        itemCompletedCount: PropTypes.number,
        atLeastOneItemCompleted: PropTypes.bool,
      }),
      PropTypes.shape({
        isMilestone: PropTypes.bool,
        items: PropTypes.arrayOf(
          PropTypes.shape({
            title: PropTypes.string.isRequired,
            completionDate: PropTypes.objectOf(MILESTONE_STATUS).isRequired,
          }),
        ),
      }),
    ]),
  ),
  chartItems: PropTypes.array,
  goToSummary: PropTypes.func,
};

export default JobSteps;
