import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ChartHeader from '../ChartHeader';
import SubHeader, { SubHeaderItemType } from './SubHeader';
import ContainerWithNotifications from '../ContainerWithNotifications';
import JobSteps from './JobSteps';
import { useSelector } from 'react-redux';
import { COMPLETION_DATE_STATUS } from '../../../constants/salesOrder';
import { removeLeadingZeroes } from '../../../utils/numbers';

const jobStages = {
  businessDevelopment: 'businessDevelopment',
  technology: 'technology',
  manufacturing: 'manufacturing',
  operations: 'operations',
  postJobActivities: 'postJobActivities',
};

const JobProgress = (props) => {
  const salesOrder = useSelector((state) => state.salesOrder.value);

  const parseDate = (date) => {
    if (date === COMPLETION_DATE_STATUS.TBD) return COMPLETION_DATE_STATUS.TBD;
    return date ? new Date(date).toLocaleDateString('en-US') : COMPLETION_DATE_STATUS.TBD;
  };

  const [stepItems, setStepItems] = useState(generateStepItems(parseDate, salesOrder));

  const [chartItems, setChartItems] = useState(generateChartItems(stepItems));

  const [subHeaderItems, setSubHeaderItems] = useState(
    generateSubHeaderItems(salesOrder, chartItems),
  );

  useEffect(() => {
    setStepItems(generateStepItems(parseDate, salesOrder));
  }, [salesOrder]);

  useEffect(() => {
    setChartItems(generateChartItems(stepItems));
  }, [stepItems]);

  useEffect(() => {
    setSubHeaderItems(generateSubHeaderItems(salesOrder, chartItems));
  }, [chartItems, salesOrder]);

  return (
    <>
      <ChartHeader
        subHeaderComponent={
          <SubHeader
            subHeaderTitle={`${salesOrder.project.name}`}
            subHeaderItems={subHeaderItems}
            stagesComplete={
              chartItems.filter((item) => item.completed / item.total).length === chartItems.length
            }
          />
        }
        chartItems={chartItems}
      />
      <div className="job-steps-titles">
        <h1 className="job-steps-title">Project Stages</h1>
        <h1 className="notifications-title">Notifications</h1>
      </div>
      <ContainerWithNotifications
        content={
          <JobSteps steps={stepItems} chartItems={chartItems} goToSummary={props.goToSummary} />
        }
      />
    </>
  );
};

JobProgress.propTypes = {
  salesOrderId: PropTypes.string,
  goToSummary: PropTypes.func,
};

// #region Generator functions

const generateSubHeaderItems = (salesOrder, chartItems) => [
  {
    type: SubHeaderItemType.INFO.key,
    title: 'Project ID',
    value: salesOrder.project.projectId,
  },
  {
    type: SubHeaderItemType.INFO.key,
    title: 'Customer Name',
    value: salesOrder.project.customerName,
  },
  {
    type: SubHeaderItemType.INFO.key,
    title: 'Region',
    value: salesOrder.project.region,
  },
  {
    type: SubHeaderItemType.INFO.key,
    title: 'Quote Count',
    value: salesOrder.project.quoteCount,
  },
  {
    type: SubHeaderItemType.INFO.key,
    title: 'Job Count',
    value: salesOrder.project.jobCount,
  },
  {
    type: SubHeaderItemType.JOB_STAGE.key,
    title: SubHeaderItemType.JOB_STAGE.title,
    value: chartItems
      .filter((item) => {
        const notComplete = item.completed / item.total < 1;
        const started = item.completed > 0;
        return (notComplete && started) || (notComplete && item.atLeastOneFieldStartedOnAnyItem);
      })
      .map((item) => item.title),
  },
];

const generateStepItems = (parseDate, salesOrder) => [
  {
    id: jobStages.businessDevelopment,
    title: 'Business Development',
    subtitle: 'Details about your Quotes',
    itemHeaders: [
      { title: 'Quote ID', size: 25, boldValue: true },
      { title: 'Submitted to Customer Date', size: 25, countForPercentage: false },
      { title: 'Quote Validity Date', size: 25, countForPercentage: false },
      { title: 'LOI Received Date', size: 20, countForPercentage: true },
    ],
    itemValues: salesOrder.stages.businessDevelopment.map((item) => [
      item.quoteId,
      parseDate(item.submittedToCustomerDate),
      parseDate(item.quoteValidityDate),
      parseDate(item.loiReceivedDate),
    ]),
    rowProgress: (item) => {
      const submittedToCustomerDate = item[1];
      const quoteValidityDate = item[2];
      const loiReceivedDate = item[3];

      if (loiReceivedDate === COMPLETION_DATE_STATUS.TBD) {
        return COMPLETION_DATE_STATUS.TBD;
      } else {
        // TODO: Missing to check if/when COMPLETION_DATE_STATUS.CANCELLED
        const loiReceivedDateInTheFuture = new Date(loiReceivedDate) > new Date();
        return loiReceivedDateInTheFuture
          ? COMPLETION_DATE_STATUS.IN_PROGRESS
          : COMPLETION_DATE_STATUS.COMPLETED;
      }
    },
    itemCompletedCount: salesOrder.stages.businessDevelopment.filter(
      (item) => parseDate(item.loiReceivedDate) !== COMPLETION_DATE_STATUS.TBD,
    ).length,
    atLeastOneFieldStartedOnAnyItem: salesOrder.stages.businessDevelopment.find(
      (item) =>
        parseDate(item.submittedToCustomerDate) !== COMPLETION_DATE_STATUS.TBD ||
        parseDate(item.quoteValidityDate) !== COMPLETION_DATE_STATUS.TBD ||
        parseDate(item.loiReceivedDate) !== COMPLETION_DATE_STATUS.TBD,
    ),
  },
  // {
  //   isMilestone: true,
  //   items: salesOrder.stages.contractSigned,
  // },
  {
    id: jobStages.technology,
    title: 'Technology',
    subtitle: 'Details about your RTAs',
    itemHeaders: [
      { title: 'RTA Number', size: 25, boldValue: true },
      { title: 'Submitted', size: 25, countForPercentage: true },
      { title: 'Target Completed Date', size: 25, countForPercentage: false },
      { title: 'Actual Completed Date', size: 20, countForPercentage: true },
    ],
    itemValues: salesOrder.stages.technology.map((item) => [
      item.rtaNumber,
      parseDate(item.submittedDate),
      parseDate(item.targetCompletedDate),
      parseDate(item.actualCompletedDate),
    ]),
    rowProgress: (item) => {
      const submittedDate = item[1];
      const targetCompletedDate = item[2];
      const actualCompletedDate = item[3];

      if (actualCompletedDate === COMPLETION_DATE_STATUS.TBD) {
        return COMPLETION_DATE_STATUS.TBD;
      } else {
        // TODO: Missing to check if/when COMPLETION_DATE_STATUS.CANCELLED
        const actualCompletedDateInTheFuture = new Date(actualCompletedDate) > new Date();
        return actualCompletedDateInTheFuture
          ? COMPLETION_DATE_STATUS.IN_PROGRESS
          : COMPLETION_DATE_STATUS.COMPLETED;
      }
    },
    itemCompletedCount: salesOrder.stages.technology.filter(
      (item) => parseDate(item.actualCompletedDate) !== COMPLETION_DATE_STATUS.TBD,
    ).length,
    atLeastOneFieldStartedOnAnyItem: salesOrder.stages.technology.find(
      (item) =>
        parseDate(item.submittedDate) !== COMPLETION_DATE_STATUS.TBD ||
        parseDate(item.targetCompletedDate) !== COMPLETION_DATE_STATUS.TBD ||
        parseDate(item.actualCompletedDate) !== COMPLETION_DATE_STATUS.TBD,
    ),
  },
  {
    id: jobStages.manufacturing,
    title: 'Manufacturing',
    subtitle: 'Details about your Manufacturing Sales Orders',
    itemHeaders: [
      { title: 'SO', size: 20, boldValue: true },
      { title: 'Line Item', size: 20 },
      { title: 'Material Number', size: 20 },
      { title: 'Target Ex Works', size: 20, countForPercentage: true },
      { title: 'Projected Complete Date', size: 20, countForPercentage: true },
    ],
    itemValues: salesOrder.stages.manufacturingData.map((item) => [
      item.soNumber,
      item.soItemNumber,
      removeLeadingZeroes(item.materialNumber),
      parseDate(item.targetExWorksCompleteDate),
      parseDate(item.projectedCompleteDate),
    ]),
    rowProgress: (item) => {
      const targetExWorksCompleteDate = item[3];
      const projectedCompleteDate = item[4];

      if (projectedCompleteDate === COMPLETION_DATE_STATUS.TBD) {
        return COMPLETION_DATE_STATUS.TBD;
      } else {
        // TODO: Missing to check if/when COMPLETION_DATE_STATUS.CANCELLED
        const projectedCompleteDateInTheFuture = new Date(projectedCompleteDate) > new Date();
        return projectedCompleteDateInTheFuture
          ? COMPLETION_DATE_STATUS.IN_PROGRESS
          : COMPLETION_DATE_STATUS.COMPLETED;
      }
    },
    itemCompletedCount: salesOrder.stages.manufacturingData.filter(
      (item) =>
        parseDate(item.targetExWorksCompleteDate) !== COMPLETION_DATE_STATUS.TBD &&
        parseDate(item.projectedCompleteDate) !== COMPLETION_DATE_STATUS.TBD,
    ).length,
    atLeastOneFieldStartedOnAnyItem: salesOrder.stages.manufacturingData.find(
      (item) =>
        parseDate(item.targetExWorksCompleteDate) !== COMPLETION_DATE_STATUS.TBD ||
        parseDate(item.projectedCompleteDate) !== COMPLETION_DATE_STATUS.TBD,
    ),
  },
  {
    id: jobStages.operations,
    title: 'Operations',
    subtitle: 'Details about your Jobs',
    itemHeaders: [
      { title: 'SO', size: 15, boldValue: true, link: true },
      { title: 'DOS', size: 20 },
      { title: 'Job ID', size: 20 },
      { title: 'Est. Job Timing', size: 20 },
      { title: 'Actual on Location Date', size: 20, countForPercentage: true },
    ],
    itemValues: salesOrder.stages.operation.map((item) => [
      item.soNumber,
      item.dosNumber,
      item.jobId,
      item.jobTiming,
      parseDate(item.actualOnLocationDate),
    ]),
    rowProgress: (item) => {
      const actualOnLocationDate = item[4];

      if (actualOnLocationDate === COMPLETION_DATE_STATUS.TBD) {
        return COMPLETION_DATE_STATUS.TBD;
      } else {
        // TODO: Missing to check if/when COMPLETION_DATE_STATUS.CANCELLED
        const actualOnLocationDateInTheFuture = new Date(actualOnLocationDate) > new Date();
        return actualOnLocationDateInTheFuture
          ? COMPLETION_DATE_STATUS.IN_PROGRESS
          : COMPLETION_DATE_STATUS.COMPLETED;
      }
    },
    itemCompletedCount: salesOrder.stages.operation.filter(
      (item) => parseDate(item.actualOnLocationDate) !== COMPLETION_DATE_STATUS.TBD,
    ).length,
    atLeastOneFieldStartedOnAnyItem: salesOrder.stages.operation.find(
      (item) => parseDate(item.actualOnLocationDate) !== COMPLETION_DATE_STATUS.TBD,
    ),
  },
  {
    isMilestone: true,
    items: salesOrder.stages.dispatchedToField,
  },
  {
    id: jobStages.postJobActivities,
    title: 'Post Job Activities',
    subtitle: 'Details about your Project Closeout',
    itemHeaders: [
      { title: 'SO', size: 25, boldValue: true },
      { title: 'DOS', size: 25 },
      { title: 'Job ID', size: 25 },
      { title: 'Job Close Date', size: 20, countForPercentage: true },
    ],
    itemValues: salesOrder.stages.postJobActivity.map((item) => [
      item.soNumber,
      item.dosNumber,
      item.jobId,
      parseDate(item.jobCloseDate),
    ]),
    rowProgress: (item) => {
      const jobCloseDate = item[3];

      if (jobCloseDate === COMPLETION_DATE_STATUS.TBD) {
        return COMPLETION_DATE_STATUS.TBD;
      } else {
        // TODO: Missing to check if/when COMPLETION_DATE_STATUS.CANCELLED
        const jobCloseDateInTheFuture = new Date(jobCloseDate) > new Date();
        return jobCloseDateInTheFuture
          ? COMPLETION_DATE_STATUS.IN_PROGRESS
          : COMPLETION_DATE_STATUS.COMPLETED;
      }
    },
    itemCompletedCount: salesOrder.stages.postJobActivity.filter(
      (item) => parseDate(item.jobCloseDate) !== COMPLETION_DATE_STATUS.TBD,
    ).length,
    atLeastOneFieldStartedOnAnyItem: salesOrder.stages.postJobActivity.find(
      (item) => parseDate(item.jobCloseDate) !== COMPLETION_DATE_STATUS.TBD,
    ),
  },
];

const calculateCompletedCount = (stepItems, jobStageId) => {
  const stageStepItems = stepItems.find((item) => item.id === jobStageId);
  return stageStepItems.itemValues.reduce((acc, item) => {
    const rowProgress = stageStepItems.rowProgress(item);
    const progress =
      rowProgress === COMPLETION_DATE_STATUS.COMPLETED
        ? 1
        : rowProgress === COMPLETION_DATE_STATUS.IN_PROGRESS
        ? 0.5
        : 0;
    return acc + progress;
  }, 0);
};

const generateChartItems = (stepItems) => [
  {
    id: jobStages.businessDevelopment,
    title: 'Business Dev',
    completed: calculateCompletedCount(stepItems, jobStages.businessDevelopment),
    total: stepItems.find((item) => item.id === jobStages.businessDevelopment).itemValues.length,
    atLeastOneFieldStartedOnAnyItem: stepItems.find(
      (item) => item.id === jobStages.businessDevelopment,
    ).atLeastOneFieldStartedOnAnyItem,
  },
  {
    id: jobStages.technology,
    title: 'Technology',
    completed: calculateCompletedCount(stepItems, jobStages.technology),
    total: stepItems.find((item) => item.id === jobStages.technology).itemValues.length,
    atLeastOneFieldStartedOnAnyItem: stepItems.find((item) => item.id === jobStages.technology)
      .atLeastOneFieldStartedOnAnyItem,
  },
  {
    id: jobStages.manufacturing,
    title: 'Manufacturing',
    completed: calculateCompletedCount(stepItems, jobStages.manufacturing),
    total: stepItems.find((item) => item.id === jobStages.manufacturing).itemValues.length,
    atLeastOneFieldStartedOnAnyItem: stepItems.find((item) => item.id === jobStages.manufacturing)
      .atLeastOneFieldStartedOnAnyItem,
  },
  {
    id: jobStages.operations,
    title: 'Operations',
    completed: calculateCompletedCount(stepItems, jobStages.operations),
    total: stepItems.find((item) => item.id === jobStages.operations).itemValues.length,
    atLeastOneFieldStartedOnAnyItem: stepItems.find((item) => item.id === jobStages.operations)
      .atLeastOneFieldStartedOnAnyItem,
  },
  {
    id: jobStages.postJobActivities,
    title: 'Post Job Act',
    completed: calculateCompletedCount(stepItems, jobStages.postJobActivities),
    total: stepItems.find((item) => item.id === jobStages.postJobActivities).itemValues.length,
    atLeastOneFieldStartedOnAnyItem: stepItems.find(
      (item) => item.id === jobStages.postJobActivities,
    ).atLeastOneFieldStartedOnAnyItem,
  },
];

// #endregion Generator functions

export default JobProgress;
